<template>
  <div>
    <full-table
      :other-filter-props="{
        add: true,
        search: true,
        gender: true,
        level: true,
        group: true,
      }"
      :items="GET_USERS_LIST.results"
      :fields="fields"
      :rows="GET_USERS_LIST.count"
      :edit-btn="false"
      :first-params="params"
      @refresh="FETCH_USERS_LIST"
      @delete-button-clicked="modalDeletePupil"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @add-button-clicked="addUser"
    />

    <!-- @detail-button-clicked="id => $router.push(`/users/students/info/${id}`)" -->

    <add-modal
      :default-role="defRole"
      :default-params="params"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import FullTable from '@/components/FullTable.vue'
import AddModal from '../components/modals/AddUserModal.vue'

export default {
  components: {
    AddModal,
    FullTable,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
        role__unique_name: 'student',
      },
      defRole: 'student',
      fields: [
        {
          key: 'contact.id',
          label: 'ID',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          key: 'contact.phone',
          label: 'users.phone_number',
        },
        {
          key: 'contact.email',
          label: 'users.email',
        },
        // {
        //   key: 'contact.sciences',
        //   label: 'users.access_predmets',
        // },
        {
          key: 'contact.level',
          label: 'users.level',
        },
        {
          key: 'contact.class',
          label: 'users.class',
        },
        {
          key: 'contact.is_active',
          label: 'users.status',
        },
        {
          key: 'actions',
          label: 'general.actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
  },

  beforeDestroy() {
    this.SET_USERS_LIST([])
  },
  methods: {
    ...mapMutations('users', ['SET_USERS_LIST']),
    ...mapActions('users', ['FETCH_USERS_LIST', 'DELETE_USER']),
    addUser() {
      this.$bvModal.show('AddModalUser')
    },
    modalDeletePupil(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER(id)
            .then(() => {
              this.FETCH_USERS_LIST(this.params)
              this.params.page = 1
              this.$_successToast('Успешно удалено')
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
